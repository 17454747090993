import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Tags from './Components/Tags';
import Navbar from './Components/Navbar';
import Carousel from './Components/Carousel';
import NewBook from './Components/NewBook';
import Footer from './Components/Footer';
import EhmBayan from './Components/EhmBayan';
import KhanqahTaruf from './Components/Khanqah/KhanqahTaruf';
import AnwareMadina from './Components/AnwareMadina';
import BookDetail from './Components/BookDetail';
import { Bayaans } from './Components/Bayaans';
import BayanDetail from './Components/BayanDetail.js';
import MaqalatHamidiya from './Components/Maqalat-e-Hamdiyah.js';
import MaqalatMian from './Components/Maqalat-e-Mian.js';
import DarulIftaPage from './Components/Darulifta.js';
import AnwareMadinaHomePage from './Components/HomePage-AnwareMadina.js';

function App() {
  const [mode, setMode] = useState('dark');

  return (
    <Router>
      <div className="app-container">
        <Navbar title="خانقاه إمدادية أشرفية" mode={mode} aboutText="" />
        <div className="content-wrapper">
          <Routes>
            <Route path="/" element={
              <>
                <Carousel heading="Enter Text To Analyze" />
                <div className="scroll-content">
                  <KhanqahTaruf />
                  <Tags />
                  <NewBook />
                  <EhmBayan />
                  <AnwareMadinaHomePage/>
                </div>
              </>
            } />
            <Route path="/AnwareMadina" element={<AnwareMadina />} />
            <Route path="/anwaremadina/:slug" element={<BookDetail />} />
            <Route path="/bayaans" element={<Bayaans />} />
            <Route path="/bayaans/:id/:bayaanType" element={<BayanDetail />} />
            <Route path="/bayaans/:id" element={<BayanDetail />} />
            <Route path="/Maqalat-e-Mian" element={<MaqalatMian />} />
            <Route path="/Maqalat-e-Hamdiyah" element={<MaqalatHamidiya />} />
            <Route path="/Darulifta" element={<DarulIftaPage/>} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;