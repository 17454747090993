import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuthorNames, REACT_BACKEND_URL } from './utils/utils';
import './BayanDetail.css';
import { Bayaans } from './Bayaans';
import Pagination from './Pagination';

const BayanDetail = () => {
  const { id, bayaanType } = useParams();
  const [bayans, setBayans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const bayansPerPage = 10;  // Adjust this number as needed

  useEffect(() => {
    const fetchBayanDetail = async () => {
      try {
        let response = {};
        if (bayaanType)
          response = await fetch(`${REACT_BACKEND_URL}/user_bayaans?author=${getAuthorNames?.[id]}&bayaan_type=${bayaanType}`);
        else
          response = await fetch(`${REACT_BACKEND_URL}/user_bayaans?author=${getAuthorNames?.[id]}`);
        const data = await response.json();
        if (data.length > 0) {
          setBayans(data);
        } else {
          console.error('Bayan not found');
        }
      } catch (error) {
        console.error('Error fetching bayan detail:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBayanDetail();
  }, [id, bayaanType]);

  if (loading) return <p className="bayan-detail-loading">Loading bayan details...</p>;

  // Get current bayans
  const indexOfLastBayan = currentPage * bayansPerPage;
  const indexOfFirstBayan = indexOfLastBayan - bayansPerPage;
  const currentBayans = bayans.slice(indexOfFirstBayan, indexOfLastBayan);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="bayan-detail-container">
      <Bayaans bayans={currentBayans} />
      {bayans.length > bayansPerPage && (
        <Pagination 
          currentPage={currentPage}
          totalPages={Math.ceil(bayans.length / bayansPerPage)}
          onPageChange={paginate}
        />
      )}
    </div>
  );
};

export default BayanDetail;