import React, { useEffect, useState } from 'react';
import axios from 'axios';
import newbookbg from '../images/newbookbg.jpg';
import './NewBook.css';
import { REACT_BACKEND_URL } from './utils/utils';

export default function NewBook() {
  const [latestBook, setLatestBook] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestBook = async () => {
      try {
        const response = await axios.get(`${REACT_BACKEND_URL}/user_books?limit=1&sort=desc`);
        const sortedBooks = response.data.sort(
          (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
        );
        setLatestBook(sortedBooks[0]); // The most recent book
        setLoading(false);
      } catch (error) {
        console.error('Error fetching latest book:', error);
        setLoading(false);
      }
    };

    fetchLatestBook();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!latestBook) {
    return <p>No recent book available.</p>;
  }

  const { book_icon, book_name, book_subname } = latestBook;

  return (
    <div className="NewBookContainer">
      <img src={newbookbg} alt="Background" className="NewBookBackground" />
      <div className="NewBookBox">
        <div className="NewBookHeader">
          <h1>نئی کتاب</h1>
        </div>
        <div className="NewBookContent">
          <div className="NewBookIcon">
            <img src={book_icon} className="zadsaeed" alt={book_name} />
          </div>
          <div className="NewBookBox-text">
            <h2>{book_name}</h2>
            <span>{book_subname}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
