import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ProductSection.css';
import { REACT_BACKEND_URL } from './utils/utils';

const ProductSection = ({ year }) => {
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get(`${REACT_BACKEND_URL}/user_books?year=${year}`);
        console.log(`Books for year ${year}:`, response.data);
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchBooks();
  }, [year]);

  const BookCard = ({ book }) => {
    const { slug_url, book_icon, book_name, book_subname } = book;

    return (
      <div className="product-card" onClick={() => navigate(`/anwaremadina/${slug_url}`)}>
        <div className="product-image">
          <img src={book_icon} className="product-thumb" alt="" />
          <button className="card-btn">ڈاؤن لوڈ کریں</button>
        </div>
        <div className="product-info">
          <h2 className="product-brand">
            <a style={{ color: '#4e4e4e' }} href={`/anwaremadina/${slug_url}`}>
              {book_name}
            </a>
          </h2>
          <p className="product-short-description">{book_subname}</p>
        </div>
      </div>
    );
  };

  return (
    <section className="product">
      <h2 className="product-category">سال {year}</h2>
      <div className="product-container">
        {books.length > 0 ? (
          books.map(book => <BookCard key={book.book_id} book={book} />)
        ) : (
          <p>No books available for the selected year.</p>
        )}
      </div>
    </section>
  );
};

export default ProductSection;
