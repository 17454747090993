import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { REACT_BACKEND_URL } from './utils/utils';
import './BookDetail.css'

const BookDetail = () => {
  const { slug } = useParams();
  const [book, setBook] = useState(null);

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const response = await axios.get(`${REACT_BACKEND_URL}/user_books?slug_url=${slug}`);
        if (response.data.length > 0) {
          setBook(response.data[0]);
        } else {
          console.error('No book found with the given slug.');
        }
      } catch (error) {
        console.error('Error fetching book:', error);
      }
    };
    fetchBook();
  }, [slug]);

  if (!book) {
    return <div className="book-detail-loading">Loading...</div>;
  }

  const pdfURL = `https://www.jamiamadniajadeed.org/anwaremadina/${book.book_pdf}`;

  return (
    <div className="book-detail-container">
      <div className="book-detail-content">
        <h1 className="book-detail-title">{book.book_name}</h1>
        <h2 className="book-detail-subtitle">{book.book_subname}</h2>
        <div className="book-detail-image-container">
          <img className="book-detail-image" src={book.book_icon} alt={book.book_name} />
        </div>
        <p className="book-detail-description">{book.book_description}</p>
        <a 
          href={pdfURL} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="book-detail-button"
        >
          Download PDF
        </a>
      </div>
    </div>
  );
};

export default BookDetail;