import React from 'react';
import BgJamia from '../images/BgJamia.png';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export default function Carousel(props) {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-ride="carousel"
      style={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <div className="carousel-inner" style={{ height: '100%' }}>
        <div className="carousel-item active" style={{ height: '100%' }}>
          <img
            className="d-block w-100"
            src={BgJamia}
            alt="First slide"
            style={{
              objectFit: 'cover',
              height: '100%',
              width: '100%'
            }}
          />
        </div>
        <div className="carousel-item" style={{ height: '100%' }}>
          <img
            className="d-block w-100"
            src={BgJamia}
            alt="Second slide"
            style={{
              objectFit: 'cover',
              height: '100%',
              width: '100%'
            }}
          />
        </div>
      </div>
      <a
        className="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '20px',
          zIndex: 10
        }}
      >
        <ChevronLeft size={24} color="white" />
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '20px',
          zIndex: 10
        }}
      >
        <ChevronRight size={24} color="white" />
        <span className="sr-only">Next</span>
      </a>
      <style jsx>{`
        @media (max-width: 768px) {
          #carouselExampleIndicators {
            height: 50vh;
          }
          .carousel-control-prev,
          .carousel-control-next {
            width: 40px;
            height: 40px;
          }
          .carousel-control-prev {
            left: 10px;
          }
          .carousel-control-next {
            right: 10px;
          }
        }
        @media (max-width: 480px) {
          #carouselExampleIndicators {
            height: 30vh;
          }
          .carousel-control-prev,
          .carousel-control-next {
            width: 30px;
            height: 30px;
          }
          .carousel-control-prev {
            left: 5px;
          }
          .carousel-control-next {
            right: 5px;
          }
        }
      `}</style>
    </div>
  );
}
