import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { REACT_BACKEND_URL } from './utils/utils';
import './HomePage-AnwareMadina.css';

const AnwareMadinaHomePage = () => {
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get(`${REACT_BACKEND_URL}/user_books?year=2024`);
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchBooks();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const BookCard = ({ book }) => {
    const { slug_url, book_icon, book_name, book_subname } = book;
    return (
      <div className="product-card" onClick={() => navigate(`/anwaremadina/${slug_url}`)}>
        <div className="product-image">
          <img src={book_icon} className="product-thumb" alt="" />
          <button className="card-btn">ڈاؤن لوڈ کریں</button>
        </div>
        <div className="product-info">
          <h2 className="product-brand">
            <a style={{ color: '#4e4e4e' }} href={`/anwaremadina/${slug_url}`}>{book_name}</a>
          </h2>
          <p className="product-short-description">{book_subname}</p>
        </div>
      </div>
    );
  };

  return (
    <div 
    className='homepage-container'
    style={{width:'100%', marginTop:'20px',  marginBottom:'20px',  display:'flex',  justifyContent:'center'}}>

    <div style={{width:'95%'}} className='c2'>
      <h2 className="product-category">سال 2024</h2>
      <Slider {...settings}>
        {books.map(book => (
          <div key={book.book_id}>
            <BookCard book={book} />
          </div>
        ))}
      </Slider>
    </div>
    </div>

  );
};

export default AnwareMadinaHomePage;





