import React, { useState } from 'react';
import './BayanDetail.css';

const Modal = ({ show, bayan, handleClose }) => {
  if (!show || !bayan) return null;

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="bayan-detail-title">{bayan.bayaan_name}</h2>
        <p className="bayan-detail-description">
          <strong>Description:</strong> {bayan.bayaan_description}
        </p>
        <p className="bayan-detail-info">
          <strong>Author:</strong> {bayan.bayaan_author}
        </p>
        <p className="bayan-detail-info">
          <strong>Date Created:</strong> {new Date(bayan.date_created).toLocaleDateString()}
        </p>
        <audio src={bayan.bayaan_link} controls className="bayan-detail-audio" />
        <div className="bayan-detail-links">
          <a href={bayan.bayaan_link} target="_blank" rel="noopener noreferrer" className="bayan-detail-button">
            Listen
          </a>
          <a href={bayan.bayaan_pdf} target="_blank" rel="noopener noreferrer" className="bayan-detail-button">
            View PDF
          </a>
        </div>
        <button className="modal-close-button" onClick={handleClose}>
          X
        </button>
      </div>
    </div>
  );
};

export const Bayaans = ({ bayans }) => {
  const [selectedBayan, setSelectedBayan] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (bayan) => {
    setSelectedBayan(bayan);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBayan(null);
  };

  return (
    <div>
      {/* Custom Header */}
      <div className="custom-header">
        <h1 className="header-title">درسِ حدیث</h1>
        <hr className="header-separator" />
        <p className="header-subtitle">
          قطب الاقطاب عالم بانیِ مجلسِ حضرتِ اقدس مولانا سید حامد میاں صاحب نور اللہ مرقدہ
        </p>
      </div>

      {/* List of Bayaans */}
      {bayans.map((bayan) => (
        <div key={bayan?.bayaan_id} className="bayan-detail-content" onClick={() => handleOpenModal(bayan)}>
          <h2 className="bayan-detail-title">{bayan.bayaan_name}</h2>
          <p className="bayan-detail-description">
            <strong>Description:</strong> {bayan.bayaan_description}
          </p>
          <p className="bayan-detail-info">
            <strong>Author:</strong> {bayan.bayaan_author}
          </p>
          <p className="bayan-detail-info">
            <strong>Date Created:</strong> {new Date(bayan.date_created).toLocaleDateString()}
          </p>
        </div>
      ))}

      {/* Modal */}
      <Modal show={showModal} bayan={selectedBayan} handleClose={handleCloseModal} />
    </div>
  );
};
